import * as React from "react";
import ReactMarkdown from 'react-markdown'
import Content from "../../Content";
import { Section, ButtonLink} from "../../styles";
import { getImage, GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import useWindowSize from "../../../hooks/useWindowSize";
import { ServiceContainer, BigTable, SmallTable, FlexInner, FlexInnerContent } from "./styles";

export const ServicesPageComponent = ({
  contentComponent,
  title,
  btnLink,
  underTableContent,
  tableau,
  services,
  image,
  smallImage,
  body
}) => {

  const { width } = useWindowSize();

  const slugifyTitle = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
  };

  const img = withArtDirection(getImage(image.image), [
    {
      media: "(max-width: 1024px)",
      image: getImage(smallImage.image),
    },
  ]) || image.image;
  const PageContent = contentComponent || Content;
  return (
    <>
    <Section variant="services2">
      <div className="text">
        <h2>
          {title}
        </h2>
        <PageContent content={body} />
        <ButtonLink to={btnLink.btnLink} isAction={btnLink.isAction}>
          {btnLink.btnLabel}
        </ButtonLink>
        </div>
      <div className="image">
        <GatsbyImage image={img} alt={image.alt} title={image.alt} />
      </div>
    </Section>
    <Section variant="white">
      <FlexInner>
        <h3>
          {tableau.title}
        </h3>
        <FlexInnerContent>
          {width > 1024 ?
          (<BigTable>
            <tbody>
            {tableau.lines.map((line, i) => (
              <tr key={`${line}-${i}`}>
                <td className='bold'>
                  <ReactMarkdown>
                    {line.col1}
                  </ReactMarkdown>
                </td>
                <td className={i === 0 ? 'bold' : ''} style={{textAlign: line.isHeader ? 'center' : 'inherit' }}>
                  {line.isHeader ? (
                    <ButtonLink className="white" to={`#${slugifyTitle(line.col2)}`}>
                      {line.col2}
                    </ButtonLink>
                  ) : (
                    <ReactMarkdown>
                      {line.col2}
                    </ReactMarkdown>
                  )}
                </td>
                <td className={i === 0 ? 'bold' : ''} style={{textAlign: line.isHeader ? 'center' : 'inherit' }}>
                  {line.isHeader ? (
                    <ButtonLink className="white" to={`#${slugifyTitle(line.col3)}`}>
                      {line.col3}
                    </ButtonLink>
                  ) : (
                    <ReactMarkdown>
                      {line.col3}
                    </ReactMarkdown>
                  )}
                </td>
              </tr>
            ))}
            </tbody>
          </BigTable>) :
          (
          <>
          <hr/>
            <SmallTable>
              <tbody>
              {tableau.lines.map((line, i) => (
                <tr key={`${line}-${i}`}>
                  <td className='bold'>
                    <ReactMarkdown>
                      {line.col1}
                    </ReactMarkdown>
                  </td>
                  <td className={i === 0 ? 'bold' : ''} style={{textAlign: line.isHeader ? 'center' : 'inherit' }}>
                    {line.isHeader ? (
                      <ButtonLink className="white" to={`#${slugifyTitle(line.col2)}`}>
                        {line.col2}
                      </ButtonLink>
                    ) : (
                      <ReactMarkdown>
                        {line.col2}
                      </ReactMarkdown>
                    )}
                  </td>
                </tr>
              ))}
              </tbody>
            </SmallTable>
            <hr/>
            <SmallTable>
              <tbody>
              {tableau.lines.map((line, i) => (
                <tr key={`${line}-${i}`}>
                  <td className='bold'>
                    <ReactMarkdown>
                      {line.col1}
                    </ReactMarkdown>
                  </td>
                  <td className={i === 0 ? 'bold' : ''} style={{textAlign: line.isHeader ? 'center' : 'inherit' }}>
                    {line.isHeader ? (
                      <ButtonLink className="white" to={`#${slugifyTitle(line.col3)}`}>
                        {line.col3}
                      </ButtonLink>
                    ) : (
                      <ReactMarkdown>
                        {line.col3}
                      </ReactMarkdown>
                    )}
                  </td>
                </tr>
              ))}
              </tbody>
            </SmallTable>
            <hr/>
          </>
          )
          }
          <ReactMarkdown>
            {tableau.body}
          </ReactMarkdown>
        </FlexInnerContent>
      </FlexInner>
      <FlexInner>
        <ReactMarkdown>
          {underTableContent}
        </ReactMarkdown>
      </FlexInner>
    </Section>
    {services.length > 0 && (
      <Section variant="services2">
        <div>
          {services.map((service, i) => (
            <ServiceContainer key={i} style={{marginBottom: 40}}>
              <h3 id={slugifyTitle(service.title)}>
                {service.title}
              </h3>
              <ReactMarkdown>
                {service.body}
              </ReactMarkdown>
              {service.btnLink && service.btnLink.btnLink && (
                <ButtonLink to={service.btnLink.btnLink} isAction={service.btnLink.isAction}>
                  {service.btnLink.btnLabel}
                </ButtonLink>
              )}
            </ServiceContainer>
          ))}
        </div>
      </Section>
    )}
    </>
  );
}