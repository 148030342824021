import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Core/Layout";
import { HTMLContent } from "../components/Content";
import { ServicesPageComponent } from "../components/Pages/Services";

// eslint-disable-next-line
export const ServicesPageTemplate = (props) => {
  return (
    <ServicesPageComponent {...props} />
  );
};

ServicesPageTemplate.propTypes = {
  // TODO
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ServicesPage = ({ data }) => {
  const { markdownRemark: content } = data;
  const { frontmatter } = content;
  return (
    <Layout>
      <ServicesPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        btnLink={frontmatter.btnLink}
        underTableContent={frontmatter.underTableContent}
        tableau={frontmatter.tableau}
        services={frontmatter.services}
        image={frontmatter.image}
        smallImage={frontmatter.smallImage}
        body={content.html}
      />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicesPage;

export { Head } from "../components/Core/HtmlHead";

export const servicesPageQuery = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter{
        title
        btnLink {
          btnLink
          btnLabel
          isAction
        }
        tableau {
          title
          lines {
            col1
            col2
            col3
            isHeader
          }
          body
        }
        underTableContent
        services {
          title
          btnLink {
            btnLink
            btnLabel
            isAction
          }
          body
        }
        image{
          ...imageBlock
        }
        smallImage: image {
          ...imageSmallBlock
        }
      }
    }
  }
`;
