import styled from "styled-components";

export const ServiceContainer = styled.div`
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid white;
  &:last-child{
    padding-bottom: 0px;
    margin-bottom: 0px;
    border-bottom: none;
  }
  & h3 {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
  }
  & p {
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  }
  & ul{
    margin-bottom: 24px;
  }
  & > .gatsby-image-wrapper{
    margin-left: auto;
    margin-right: auto;
  }
`;

export const BigTable = styled.table`
  & > tbody {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    & > tr > .bold {
      font-weight: 700;
    }

    & > tr > td{
      padding: 8px;
      border-right: 1px solid #000;
      border-left: none;
      border-top: none;
    }
    & > tr > td:last-of-type {
      border-right: none;
    }
    & > tr > td > p {
      padding: 5px 13px;
    }
  }
`;

export const SmallTable = styled.table`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
  & > tbody > tr > .bold {
    font-weight: 700;
  }
  & > tbody > tr > td > p {
    padding: 18px 13px;
  }
`;

export const FlexInner = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px){
    flex-direction: row;
    & > h3{
      width: 25%;
      min-width: 25%;
    }
  }
`;

export const FlexInnerContent = styled.div`
  
`;
